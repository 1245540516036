import React from 'react';
import './App.css';
// import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from './pages/Home';
import LandingPage from './pages/LandingPage';

function App() {
return (
	<Router>
	{/* <Navbar /> */}
	<Routes>
		<Route path='/' element={<LandingPage/>} />
	</Routes>
	</Router>
);
}

export default App;
