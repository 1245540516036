import './LandingPage.css';
import Logo from '../assets/icons/logo-white.png';

function LandingPage() {
    return (
        <body>
            <img style={{cursor:'pointer', marginTop: '45%', width: '500px'}}  src={Logo} alt="alignexLogo"/>
            <div style={{color: '#faf7f2', marginTop: '40px', fontSize: '30px'}}>
                Unlocking Orthodontic Excellence 
            </div>
            <div style={{color: '#faf7f2', fontSize: '30px'}}>
                with Alignex Clear Aligners
            </div>
            <div className='coming-soon-container'>
                COMING SOON!
            </div>
        </body>
    )
}

export default LandingPage;